import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import axios from 'axios';
import { debounce, isEmpty } from 'lodash';
import { Row, Col, Button, Form, Card, Space, Modal, notification } from 'antd';
import FormInput from '../components/FormInput';
import Firebase from '../lib/Firebase';
import FormSelect from '../components/FormSelect';
import FormTimePicker from '../components/FormTimePicker';
import FormDatePicker from '../components/FormDatePicker';
import FormPlacesAutocomplete from '../components/FormPlacesAutocomplete';
import NotFound from './NotFound';
import Loading from '../components/Loading';
import { getDistanceBetweenTwoLocation } from '../lib/googleMapApi';
import useWindowDimensions from '../common/hooks/useWindowDimensions';

dayjs.extend(utc);
dayjs.extend(timezone);

const titleStyle = {
	fontFamily: "Roboto",
	fontSize: 20,
	color: "#4A525F",
	fontWeight: 600,
};

const displayNotification = (description, cb = () => {}) => {
	notification.warning({
		message: "New Reservation Alert",
		description,
	});

	cb(false);

	return;
};

const formatAddress = async (address, type = "pickup", cb) => {
	const pickupQuery = `https://maps.googleapis.com/maps/api/geocode/json?address=${
		address
	}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`.replace("#", "");
	const pickupRes = await axios.get(encodeURI(pickupQuery));

	let addressLine = "";
    let city = "";
    let state = "";
    let zipcode = "";
    let latitude = 0;
    let longitude = 0;
	let validPUZipCode = false;
	let errorMsg = null;

	if (pickupRes.data.results.length) {
		latitude = pickupRes.data.results[0].geometry.location.lat;
		longitude = pickupRes.data.results[0].geometry.location.lng;

		pickupRes.data.results[0].address_components.forEach((element) => {
			if (element.types[0] === "street_number") {
				addressLine = element.long_name;
			}
			if (element.types[0] === "route") {
				addressLine += ` ${element.long_name}`;
			}
			if (element.types[0] === "postal_code") {
				validPUZipCode = true;
				zipcode = element.short_name;
			}
			if (element.types.find(elem => elem === "political") !== undefined &&
				(element.types.find(elem => elem === "locality") ||
				element.types.find(elem => elem === "sublocality") ||
				element.types.find(elem => elem === "neighborhood"))
			) {
				city = element.long_name;
			}
			// this is a patch for now ask by Jing
			if (city.toLocaleLowerCase() === "los angeles") {
			const neighborhood = pickupRes.data.results[0].address_components.filter(
				el => el.types.includes("neighborhood")
			);
			if (neighborhood.length > 0 && neighborhood[0].long_name) {
				city = neighborhood[0].long_name;
			}
			}

			if (
				element.types[0] === "administrative_area_level_1" &&
				element.types[1] === "political"
			) {
				state = element.short_name;
			}
		});
	} else {
		errorMsg = "Please enter correct address.";
	}

	if (addressLine === "") {
		errorMsg = `Please enter correct address. Invalid ${type} address line.`;
	} else if (city === "") {
		errorMsg = `Please enter correct address. Invalid ${type} city`;
	} else if (state === "") {
		errorMsg = `Please enter correct address. Invalid ${type} state.`;
	} else if (zipcode === "") {
		errorMsg = `Please enter correct address. Invalid ${type} zip code.`;
	} else if (latitude === 0) {
		errorMsg = `Please enter correct address. Invalid ${type} latitude value from address.`;
	} else if (longitude === 0) {
		errorMsg = `Please enter correct address. Invalid ${type} longitude value from address.`;
	} else if (!validPUZipCode) {
		errorMsg = "Please enter correct address!";
	} else {
		return {
			addressLine,
			city,
			state,
			zipcode,
			latitude,
			longitude,
		};
	}

	displayNotification(errorMsg, cb);

	return null;
}

function ReservationPortal({
	setLogo,
	setWebsite,
}) {
	const windowDimensions = useWindowDimensions();
	const {pathId} = useParams();
	const [programName, portalId, facilityId] = pathId.split("_");
	const [form] = Form.useForm();
	const [tripFrom, setTripFrom] = useState("Somebody else");
	const [tripLeg, setTripLeg] = useState(1);
	const [isFieldReset, setIsFieldReset] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [fleetData, loading] = Firebase.getFleetData(programName, portalId, facilityId);
	const programTimezone = fleetData?.program?.timezone || null
	const mobilityAssistanceList = fleetData?.mobility_assistance || [];
	const paymentMethodList = facilityId ? [
		// this is in alphabetical order
		// do not interchange
		{value: 1, label: `Bill to ${fleetData?.facility?.name}`},
		{value: 2, label: "Credit card or debit card"},
		{value: 0, label: "Cash or check"},
	] : [
		{value: 2, label: "Credit card or debit card"},
		{value: 0, label: "Cash or check"},
	];

	// used by Col span
	const spanWindow = windowDimensions?.width <= 680 ? 24 : 12;

	// watched forms
	const userFirstname = Form.useWatch('user_firstname', form);
	const userLastname = Form.useWatch('user_lastname', form);
	const userPhone = Form.useWatch('user_phone', form);
	const userEmail = Form.useWatch('user_email', form);

	const onFinish = debounce(async (values) => {
		try {
			setIsSubmitting(true);

			const {
				dropoff_address,
				pickup_address,
				user_phone,
				rider_phone,
			} = values;

			if (user_phone && user_phone.length !== 14) {
				displayNotification("Customer's phone format is invalid.", setIsSubmitting);

				return;
			}

			if (rider_phone && rider_phone.length !== 14) {
				displayNotification("Rider's phone format is invalid.", setIsSubmitting);

				return;
			}

			if (pickup_address === dropoff_address) {
				displayNotification("The pickup and drop-off addresses can't be the same. Please correct this.", setIsSubmitting);

				return;
			}

			const formattedPickupAddress = await formatAddress(pickup_address, "pickup", setIsSubmitting);
			const formattedDropoffAddress = formattedPickupAddress && await formatAddress(dropoff_address, "dropoff", setIsSubmitting);

			let meters = 0;
			let duration = 0;

			try {
				// get travel distance and time
				const origin = pickup_address;
				const destination = dropoff_address;

				const routesBetween = await getDistanceBetweenTwoLocation(
					origin,
					destination
				);

				meters = routesBetween.rows[0].elements[0].distance.value;
				duration = routesBetween.rows[0].elements[0].duration.value;
			} catch (error) {
				displayNotification("Please enter correct address!", setIsSubmitting);

				return;
			}

			if (formattedPickupAddress && formattedDropoffAddress) {
				values.public_portal_id = portalId;
				values.travel_distance = meters;
				values.travel_time = duration;
				values.pickup_address = formattedPickupAddress;
				values.dropoff_address = formattedDropoffAddress;
				values.program_id = fleetData.program.id;
				values.pickup_date = values.pickup_date && dayjs(values.pickup_date).format("dddd, MMMM D, YYYY");
				values.return_date = values.return_date && dayjs(values.return_date).format("dddd, MMMM D, YYYY");
				values.pickup_time = values.pickup_time === "Will call" ? "11:59 PM" : values.pickup_time;
				values.return_time = values.return_time === "Will call" ? "11:59 PM" : values.return_time;
				values.appointment_time = values.appointment_time === "Will call" ? "11:59 PM" : values.appointment_time;
				values.mobility_assistance = values.mobility_assistance === "None"
					? [] : [values.mobility_assistance];
				values.office_note =
					"Submitted through Portal by:\n"
					+ `First Name: ${(values.user_firstname || "").trim()}\n`
					+ `Last Name: ${(values.user_lastname || "").trim()}\n`
					+ `Mobile Phone: ${values.user_phone}\n`
					+ `Email: ${values.user_email}\n`
					+ "\n"
					+ "Rider information:\n"
					+ `First Name: ${(values.rider_firstname || "").trim()}\n`
					+ `Last Name: ${(values.rider_lastname || "").trim()}\n`
					+ `Mobile Phone: ${values.rider_phone || ""}\n`
					+ `Email: ${values.rider_email || ""}\n`
					+ "\n"
					+ `Are you flexible with pickup time: ${values.is_pickup_time_flexible || false}\n`
					+ `${values.appointment_time ? `Appointment Time: ${values.appointment_time || ""}\n` : ""}`
					+ "\n"
					+ `Notes: ${values.office_note || ""}\n`
				;

				if (facilityId) {
					values.facility_portal_id = facilityId;

					if (values.payment_method === 1) {
						values.insurance_id = fleetData?.facility.id;
					}
				}

				const functionName = "v1_webapp_public_create_new_reservation";
				const appCheckToken = await Firebase.getAppCheckToken();

				await axios.post(`${process.env.REACT_APP_DUET_SERVER_URL}/${functionName}`, {
					...values
				}, {
					headers: {
						'X-Parse-Application-Id': process.env.REACT_APP_DUET_APP_ID,
						'X-Firebase-AppCheck': appCheckToken,
						'Content-Type': 'application/json'
					},
				});

				form.resetFields();
				setIsFieldReset(true);
				setIsSubmitting(false);
				setTripFrom("Somebody else");

				notification.success({
					message: "New Reservation Success",
					description: 'The reservation is successfully created.',
				});
			}
		} catch (error) {
			notification.error({
				message: "New Reservation Error",
				description: error?.response?.data
				? error.response.data.error.message
				: error.toString(),
			});
			setIsSubmitting(false);
		}
	}, 1000);
	
	const onValuesChange = (data) => {
		const { trip_from, trip_leg } = data;

		if (trip_from) {
			setTripFrom(trip_from);
		}

		if (trip_leg) {
			setTripLeg(trip_leg);
		}
	};

	const cancelModal = () => {
		Modal.confirm({
		  title: 'Do you want to cancel this reservation?',
		  content: (
			<p>All data entered into the fields will be reset.</p>
		  ),
		  onOk: () => {
			form.resetFields();
			setIsFieldReset(true);
			setTripFrom("Somebody else");
		  },
		});
	};

	useEffect(() => {
		document.title = 'Reservation Portal Powered by Duet';
	}, []);

	useEffect(() => {
		if (fleetData) {
			setLogo(fleetData?.logo);
			fleetData?.website && setWebsite(fleetData?.website);
		}
    }, [fleetData, setLogo, setWebsite]);

	useEffect(() => {
		if (tripFrom === "Myself" && !isSubmitting) {
			if (userFirstname
				|| userLastname
				|| userPhone
				|| userEmail
				|| userFirstname === ""
				|| userLastname === ""
				|| userPhone === ""
				|| userEmail === ""
			) {
				form.setFieldsValue({
					rider_firstname: userFirstname,
					rider_lastname: userLastname,
					rider_phone: userPhone,
					rider_email: userEmail,
				});
			}
		}
	}, [form, tripFrom, userFirstname, userLastname, userPhone, userEmail, isSubmitting])

	useEffect(() => {
		if (tripFrom === "Somebody else") {
			form.setFieldsValue({
				rider_firstname: null,
				rider_lastname: null,
				rider_phone: null,
				rider_email: null,
			});
		}
	}, [form, tripFrom]);

	useEffect(() => {
		if (tripLeg === 1) {
			form.setFieldsValue({
				return_date: null,
				return_time: null,
			})
		}
	}, [tripLeg, form]);

	if (loading) {
		return <Loading />;
	} else if (isEmpty(fleetData) && !loading) {
		return <NotFound />;
	}

	return (
		<div
			style={{
				textAlign: "left",
				fontFamily: "Roboto",
			}}
		>
			<div className="fleet-title">
				<span
					style={{
						display: "block",
						fontSize: 24,
						fontWeight: 800,
						marginBottom: 20
					}}
				>
					{fleetData?.name}
				</span>
				<span
					style={{
						display: "inline-block",
						fontSize: 16,
						fontWeight: 400
					}}
				>
					{fleetData?.phone}, {fleetData?.email}
				</span>
			</div>
			<Card
				title={
					<Row style={{margin: "20px 0"}}>
						<Col span={24} style={{marginBottom: 5}}>
							<span style={{...titleStyle, fontWeight: 700}}>Inquire or reserve a trip</span>
						</Col>
						<Col span={24} style={{fontSize: 14, display: "contents"}}>
							<span style={{color: "#9398A0"}}>
								Use this form to submit your trip inquiry or get a quote.
								We will get back to you shortly.
							</span>
						</Col>
					</Row>
				}
			>
				<Form
					form={form}
					name="reservation-form"
					onFinish={onFinish}
					onValuesChange={onValuesChange}
					autoComplete="off"
				>
					<div style={{marginBottom: 20}}>
						<span style={{ ...titleStyle, fontSize: 18 }}>
							Your Contact Information
						</span>
						<Card
							size="small"
							className="description-container"
						>
							<Row gutter={[20, 0]}>
								<Col span={spanWindow}>
									<FormInput
										name="user_firstname"
										label="First Name"
										rules={[{
											pattern: /^[A-Za-z\s]*$/,
											message: "Please input letters only."
										}]}
										required
									/>
								</Col>
								<Col span={spanWindow}>
									<FormInput
										name="user_lastname"
										label="Last Name"
										rules={[{
											pattern: /^[A-Za-z\s]*$/,
											message: "Please input letters only."
										}]}
										required
									/>
								</Col>
								<Col span={spanWindow}>
									<FormInput
										type="phone"
										name="user_phone"
										label="Mobile Phone"
										setValue={(data) => form.setFieldValue("user_phone", data)}
										rules={[{
											pattern: /^[0-9()-+-\s]+$/,
											message: "Please input valid mobile phone number."
										}]}
										required
									/>
								</Col>
								<Col span={spanWindow}>
									<FormInput
										name="user_email"
										label="Email"
										rules={[{
											type: "email",
											message: "Please input valid email address.",
										}]}
										required
									/>
								</Col>
								<Col span={24}>
									<FormSelect
										name="trip_from"
										label="Who is this trip for?"
										options={[
											{ value: "Myself", label: "Myself" },
											{ value: "Somebody else", label: "Somebody else" },
										]}
										required
									/>
								</Col>
							</Row>
						</Card>
					</div>
					<div style={{marginBottom: 20}}>
						<span style={{ ...titleStyle, fontSize: 18 }}>
							Rider
						</span>
						<Card
							size="small"
							className="description-container"
						>
							<Row gutter={[20, 0]}>
								<Col span={spanWindow}>
									<FormInput
										name="rider_firstname"
										label="First Name"
										rules={[{
											pattern: /^[A-Za-z\s]*$/,
											message: "Please input letters only."
										}]}
										required
										disabled={tripFrom === "Myself"}
									/>
								</Col>
								<Col span={spanWindow}>
									<FormInput
										name="rider_lastname"
										label="Last Name"
										rules={[{
											pattern: /^[A-Za-z\s]*$/,
											message: "Please input letters only."
										}]}
										required
										disabled={tripFrom === "Myself"}
									/>
								</Col>
								<Col span={spanWindow}>
									<FormInput
										type="phone"
										name="rider_phone"
										label="Mobile Phone"
										setValue={(data) => form.setFieldValue("rider_phone", data)}
										rules={[{
											pattern: /^[0-9()-+-\s]+$/,
											message: "Please input valid mobile phone number."
										}]}
										disabled={tripFrom === "Myself"}
									/>
								</Col>
								<Col span={spanWindow}>
									<FormInput
										name="rider_email"
										label="Email"
										rules={[{
											type: "email",
											message: "Please input valid email address.",
										}]}
										disabled={tripFrom === "Myself"}
									/>
								</Col>
							</Row>
						</Card>
					</div>
					<div style={{marginBottom: 40}}>
						<span style={{ ...titleStyle, fontSize: 18 }}>
							Trip Information
						</span>
						<Card
							size="small"
							className="description-container"
						>
							<Row gutter={[20, 0]}>
								<Col span={spanWindow}>
									<FormDatePicker
										name="pickup_date"
										label="Pickup date"
										disabledDate={(current) => {
											const currentDate = dayjs(current).tz(programTimezone);
											return  currentDate.isBefore(dayjs.tz(new Date(), programTimezone).startOf("day"));
										}}
									/>
								</Col>
								<Col span={spanWindow}>
									<FormTimePicker
										name="pickup_time"
										label="Pickup time"
										selectedDate={() => form.getFieldValue("pickup_date")}
										selectedTime={() => form.getFieldValue("pickup_time")}
										timezone={programTimezone}
										isReset={isFieldReset}
										setIsReset={setIsFieldReset}
										setValue={(data) => form.setFieldValue("pickup_time", data)}
									/>
								</Col>
								<Col span={24}>
									<FormTimePicker
										name="appointment_time"
										label="Appointment or arrival time if there is any"
										selectedTime={() => form.getFieldValue("appointment_time")}
										timezone={programTimezone}
										isReset={isFieldReset}
										setIsReset={setIsFieldReset}
										setValue={(data) => form.setFieldValue("appointment_time", data)}
									/>
								</Col>
								<Col span={spanWindow}>
									<FormSelect
										name="mobility_assistance"
										label="Mobility Assistance"
										options={[{
											value: "None",
											label: "None"
										}].concat(mobilityAssistanceList.map((ma) => ({value: ma.type, ...ma}))
											.sort((a, b) => a.value - b.value))}
										required
									/>
								</Col>
								<Col span={spanWindow}>
									<FormSelect
										name="additional_passenger"
										label="Additional Passenger"
										options={[
											{value: 0, label: "None"},
											{value: 1, label: "1"},
											{value: 2, label: "2"},
											{value: 3, label: "3"},
										]}
										required
									/>
								</Col>
								<Col span={24}>
									<FormPlacesAutocomplete
										name="pickup_address"
										label="Pickup address"
										isReset={isFieldReset}
										setIsReset={setIsFieldReset}
										setValue={(data) => form.setFieldValue("pickup_address", data)}
										required
									/>
								</Col>
								<Col span={24}>
									<FormPlacesAutocomplete
										name="dropoff_address"
										label="Drop-off address"
										isReset={isFieldReset}
										setIsReset={setIsFieldReset}
										setValue={(data) => form.setFieldValue("dropoff_address", data)}
										required
									/>
								</Col>
								<Col span={24}>
									<FormSelect
										name="trip_leg"
										label="One-way or round trip"
										options={[
											{value: 1, label: "One way"},
											{value: 2, label: "Round trip"},
										]}
										required
									/>
								</Col>
								<Col span={spanWindow}>
									<FormDatePicker
										name="return_date"
										label="Return date"
										disabled={tripLeg === 1}
										disabledDate={(current) => {
											const currentDate = dayjs(current).tz(programTimezone);
											return  currentDate.isBefore(dayjs.tz(new Date(), programTimezone).startOf("day"));
										}}
									/>
								</Col>
								<Col span={spanWindow}>
									<FormTimePicker
										name="return_time"
										label="Return time"
										disabled={tripLeg === 1}
										selectedDate={() => form.getFieldValue("return_date")}
										selectedTime={() => form.getFieldValue("return_time")}
										timezone={programTimezone}
										isReset={isFieldReset}
										setIsReset={setIsFieldReset}
										setValue={(data) => form.setFieldValue("return_time", data)}
									/>
								</Col>
								<Col span={spanWindow}>
									<FormSelect
										name="payment_method"
										label="How is the trip paid?"
										options={paymentMethodList}
										required
									/>
								</Col>
								<Col span={spanWindow}>
									<FormSelect
										name="is_pickup_time_flexible"
										label="Are you flexible with pickup time?"
										options={[
											{value: "Yes", label: "Yes"},
											{value: "No", label: "No"},
										]}
										required
									/>
								</Col>
								<Col span={24}>
									<FormInput
										type="text-area"
										name="office_note"
										label="Note, anything else that we need to know about this trip?"
									/>
								</Col>
							</Row>
						</Card>
					</div>
					<Form.Item
						style={{
							textAlign: "right",
							marginBottom: 26,
						}}
					>
						<Space>
							<Button
								style={{
									borderRadius: 3,
									fontWeight: 600,
									backgroundColor: "#D7DCE5",
									color: "#4A525F"
								}}
								size="large"
								htmlType="button"
								onClick={cancelModal}
							>
								Cancel
							</Button>
							<Button
								style={{
									backgroundColor: "#118BF0",
									color: "#FFFFFF",
									borderRadius: 3,
									fontWeight: 600,
								}}
								size="large"
								htmlType="submit"
								loading={isSubmitting}
							>
								Submit
							</Button>
						</Space>
					</Form.Item>
				</Form>
			</Card>
		</div>
	);
}

export default ReservationPortal;